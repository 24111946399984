import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBedFront, faTableCellsLarge, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { getImageFromApiURl } from 'utils/http';
import { formatAsCurrency } from 'utils/currency';
import { createCompanyLink, createPropertyGroupLink, createPropertyLink } from 'utils/links';
import { useRouter } from 'next/router';
import { LanguageKey } from 'types/common';
import { format, parseISO } from 'date-fns';
import PropertyImageThumbnail from 'components/PropertyImageThumbnail';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';
import { useTranslation } from 'next-i18next';
import { AvailabilityStatus, SearchProperty } from 'tenant-client';
import { PROPERTY_TYPE_APARTMENT, PROPERTY_TYPE_HOUSE, PROPERTY_TYPE_TOWNHOUSE } from 'utils/constants';

type SearchPagePropertyCardProps = {
  property: SearchProperty;
};

const formatAvailableFrom = (availableFrom: string | undefined | null, nowTranslation: string | undefined | null) => {
  return availableFrom ? (parseISO(availableFrom) < new Date() ? nowTranslation : `${format(parseISO(availableFrom), 'dd-MM-Y')}`) : '-';
};

//Experimenting with property card styles on the serach page without breaking landing pages
const SearchPagePropertyCard = ({ property }: SearchPagePropertyCardProps) => {
  const {
    id,
    name,
    city,
    postalcode,
    size,
    rooms,
    availableFrom,
    price,
    image,
    unit,
    companyId,
    propertyGroupId,
    companyName,
    propertyGroupName,
    availabilityStatus,
    crawled,
    propertyType,
    propertySlug,
    propertyGroupSlug,
    companySlug,
  } = property;
  const { locale } = useRouter();
  const { theme } = useWhiteLabel();
  const { t } = useTranslation('general');

  const getTransactionStatusPill = (availabilityStatus: AvailabilityStatus) => {
    if (availabilityStatus === 'Available') {
      return (
        <span
          style={{ backgroundColor: theme.color.availableCardPillBackgroundColor, color: theme.color.availableCardPillTextColor }}
          className="absolute left-6 top-6 py-1 px-4 rounded-[70px] text-white text-xs font-semibold leading-tight"
        >
          {t('available')}
        </span>
      );
    }

    return (
      <span
        style={{ backgroundColor: theme.color.reservedCardPillBackgroundColor, color: theme.color.reservedCardPillTextColor }}
        className="absolute left-6 top-6 py-1 px-4 rounded-[70px] text-white text-xs font-semibold font-['Poppins'] leading-tight"
      >
        {t('reserved')}
      </span>
    );
  };

  const getPropertyType = (propertyType: number | undefined | null) => {
    if (propertyType === PROPERTY_TYPE_APARTMENT) {
      return t('apartment');
    }
    if (propertyType === PROPERTY_TYPE_TOWNHOUSE) {
      return t('townhouse');
    }
    if (propertyType === PROPERTY_TYPE_HOUSE) {
      return t('house');
    }
    return '-';
  };

  return (
    <div className="flex relative flex-col h-[420px] bg-white rounded-lg border-solid border border-gray-200 xs:max-w-[260px] w-full">
      <div className="relative w-full h-[55%] rounded-t-lg overflow-hidden">
        <div className="absolute w-full h-full">
          <Link href={createPropertyLink(id, name, propertySlug, locale as LanguageKey)} passHref>
            <a>
              <PropertyImageThumbnail
                src={getImageFromApiURl(String(image?.name), 384, true, crawled)}
                alt={name}
                backgroundColor={theme.color.propertyCardBackgroundColor}
                logoColor={theme.color.propertyCardLogoColor}
              />
            </a>
          </Link>
        </div>
      </div>
      <div className="flex flex-col px-5 pt-3 pb-4 w-full">
        <Link href={createPropertyLink(id, name, propertySlug, locale as LanguageKey)} passHref>
          <a className="mb-2 text-neutral-800 text-sm font-semibold truncate">{name}</a>
        </Link>
        <div className="flex items-center mb-2 text-[13px] leading-5">
          <FontAwesomeIcon icon={faTableCellsLarge} className="mr-2 h-3" />
          <span className="mr-3 text-slate-800 font-normal">{size || '-'} m²</span>
          <FontAwesomeIcon className="mr-2 h-3" icon={faBedFront} />
          <span className="mr-3 text-slate-800 font-normal">{rooms || '-'}</span>
          <FontAwesomeIcon className="mr-2 h-3" icon={faCalendar} />
          <span className="text-slate-800 font-normal">{formatAvailableFrom(availableFrom, t('now'))}</span>
        </div>
        <span className="text-slate-800 mb-2 text-sm truncate">
          {postalcode} {city}
        </span>
        <Link href={createPropertyGroupLink(propertyGroupId, propertyGroupName, propertyGroupSlug, locale as LanguageKey)} passHref>
          <a className="text-slate-800 mb-3 underline text-sm visited:text-slate-800 truncate">{propertyGroupName}</a>
        </Link>
        <span className="text-slate-800 mb-5 text-sm visited:text-slate-800">{getPropertyType(propertyType)}</span>
        <span className="mb-2 text-slate-800 text-lg font-bold">{formatAsCurrency(Number(price), String(unit))}</span>
        <Link href={createCompanyLink(companyId, companyName, companySlug, locale as LanguageKey)} passHref>
          <a className=" text-slate-800 text-[10px] leading-4 visited:text-slate-800 truncate">{companyName}</a>
        </Link>
      </div>
      {getTransactionStatusPill(availabilityStatus)}
    </div>
  );
};

export default SearchPagePropertyCard;
